import React from 'react';
import classNames from 'classnames/bind';

//Icons
import CrossIcon from '../../../icons/cross-grey.svg';

//Styles
import styles from './PopupGeneral.styl';

const cx = classNames.bind(styles);

const PopupGeneral = ({ text, icon, closeModal, className, classNameIcon}) => {
  return (
    <div className={cx('PopupGeneral', className)}>
      <div className={cx('PopupGeneral__left')}>
        <div className={cx('PopupGeneral__icon', classNameIcon)}>{icon}</div>
        <p className={cx('PopupGeneral__text')}>{text}</p>
      </div>
      <button className={cx('PopupGeneral__button')} onClick={closeModal}>
        <CrossIcon width={10} height={10} />
      </button>
    </div>
  );
};

export default PopupGeneral;
